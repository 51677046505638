
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import logo from './logo.svg';
import './App.css';
import beianIcon from './beian.png';

function App() {
  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit" component="div">
              技术学习
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Box>
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Playground of Kai.
            </p>
          </header>
        </div>
      </Box>
      <Box >
        <Typography align='center'>
          <img src={beianIcon} alt="beian logo"/>
          备案号：<a href="https://beian.miit.gov.cn/">京ICP备2021034070号</a>
        </Typography>
      </Box>
    </Box>
  );
}

export default App;
